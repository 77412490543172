<script setup>
import { computed, getCurrentInstance } from "vue";
import LanguageSelecter from "@/components/LanguageSelecter.vue";
import PageHeader from "@/components/PageHeader.vue";
import store from "@/store/index.js";

const el = getCurrentInstance();

const deviceType = computed(() => {
    return store.state.deviceType;
});

const loginName = computed(() => {
    const name = store.state.user.login_name;
    if (name && name.toLowerCase() !== "null" && name.toLowerCase() !== "undefined")
        return name;

    else return el.proxy.$t("x_app_title");
});

const loginLogo = computed(() => {
    const logo = store.state.user.login_logo;
    if (logo && logo.toLowerCase() !== "null" && logo.toLowerCase() !== "undefined")
        return logo;

    else return "/logoImg.ico";
});
</script>

<template>
    <PageHeader :title="loginName" :logo="loginLogo">
        <template #extra>
            <LanguageSelecter />
        </template>
    </PageHeader>
</template>

<style scoped lang="less">

</style>
