import { Message } from "view-design";
import Vue from "vue";
import Auth from "@/pages/auth/login/Auth.vue";
import Service from "@/libs/service.js";
import AuthWrapper from "@/pages/auth/AuthWrapper.vue";
import store from "@/store/index.js";

const authRoutes = [
    {
        path: "/auth",
        name: "auth",
        component: AuthWrapper,
        children: [
            {
                path: "/login",
                name: "login",
                component: Auth,
                beforeEnter: (to, from, next) => {
                    let subdomainName = "";
                    const hostitems = window.location.host.split(".");

                    const isLocalhost = window.location.host.includes("localhost");

                    if (isLocalhost && hostitems.length > 1)
                        subdomainName = hostitems[0];
                    else if (!isLocalhost && hostitems.length > 2)
                        subdomainName = hostitems[0];

                    const urlSearchParams = new URLSearchParams(window.location.href.split("?")[1]);
                    const params = Object.fromEntries(urlSearchParams.entries());

                    if (params.shareCode && params.p) {
                        localStorage.share_code = params.shareCode;
                        localStorage.share_p = params.p;
                    }

                    if (subdomainName && window.isSubDomainSupport) {
                        Service.request(
                            "get",
                            `/sys/company/logo?website=${subdomainName}`,
                            {},
                            (dt) => {
                                // localStorage.login_title = dt.data.loginTitle;
                                // localStorage.login_bg = dt.data.loginBackground;
                                // localStorage.login_logo = dt.data.logo;
                                // localStorage.login_name = dt.data.name;

                                store.commit("user/setCompanyLogoInfo", dt.data);
                            },
                            () => {
                                hostitems.shift();
                                const path = hostitems.join(".");
                                _.delay(() => {
                                    window.location.replace(
                                        `${window.location.protocol}//${path}/#/login`,
                                    );
                                }, 100);
                            },
                        );
                    }
                    if (to.query.openId) {
                        Message.warning({
                            content: new Vue().$t("x_wechat_login_err_unbind"),
                            duration: 5,
                        });
                    }
                    next();
                },
                meta: {
                    title: "x_login",
                },
            },
            {
                path: "signup/success",
                name: "signup-success",
                meta: {
                    title: "x_login",
                },
                component: () => import("@/pages/auth/login/SignupSuccess.vue"),
            },
            {
                path: "/hub",
                name: "hub",
                meta: {
                    title: "x_company",
                },
                component: () => import("@/pages/auth/hub/Hub.vue"),
            },
            {
                path: "/hub/create",
                name: "create-hub",
                meta: {
                    title: "x_company",
                },
                component: () => import("@/pages/auth/hub/CreateHub.vue"),
            },
        ],
    },
    {
        path: "/loading",
        name: "apploading",
        meta: {
            title: "x_loading",
        },
        component: () => import("@/pages/auth/apploading.vue"),
    },
];

export default authRoutes;
